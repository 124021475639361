<template>
	<v-container fixed>
		<material-card id="main-card" color="info" title="Последнее просмотренное">
			<template v-slot:headerRight>
				<v-btn text @click="clearData"><v-icon>icon-trash</v-icon></v-btn>
			</template>
			<Progress :error="error" :loading="loading" type="overlay"></Progress>

			<v-flex xs12>
				<video-list :items="items"></video-list>
			</v-flex>
		</material-card>
	</v-container>
</template>

<script>
import VideoList from "../components/video-list.vue";

export default {
	components: { VideoList },
	data() {
		return {
			loading: false,
			items: null,
			error: null,
		};
	},
	created() {
		var t = this;
		document.title = 'Последнее просмотренное';
		t.fetchData();
	},
	methods: {
		fetchData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/watched", {}, function(
				data,
				error
			) {
				t.loading = false;
				if (!error) {
					t.items = data.items;
				} else {
					t.error = data;
				}
			});
		},
		clearData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/clear-watched", {}, function() {
				t.loading = false;
				t.items = [];
			});
		},
	},
};
</script>
<style scoped>
</style>